var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('summaryReportPensionFundAndInsurance.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('summaryReportPensionFundAndInsurance.pageTitle'),
                active: true
            }
        ]}}),_c('ts-panel',[_c('ts-panel-wrapper',{attrs:{"loading":_vm.loading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card border-0 bg-gray-900 mb-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-gray-500"},[_c('b',[_vm._v(_vm._s(_vm.$t( 'summaryReportPensionFundAndInsurance.filterCriteria' )))])])])]),_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"form-label col-label required"},[_vm._v(_vm._s(_vm.$t( 'summaryReportPensionFundAndInsurance.asOfDate' )))]),_c('DatePicker',{class:{
                                'is-invalid': _vm.errors.has('as_of_date')
                            },staticStyle:{"width":"100%"},attrs:{"placement":"bottom-end","transfer":true,"format":"dd-MM-yyyy"},on:{"on-change":_vm.onChangeTransactionDate},model:{value:(_vm.as_of_date),callback:function ($$v) {_vm.as_of_date=$$v},expression:"as_of_date"}}),(_vm.errors.has('as_of_date'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first('as_of_date'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-md-12 mb-3"},[_c('label',{staticClass:"form-label col-label"},[_vm._v(_vm._s(_vm.$t( 'summaryReportPensionFundAndInsurance.branch' )))]),_c('ts-branch-filter',{attrs:{"isPlaceholder":true},on:{"filter":function (value) { return (_vm.branch_id = value); }}})],1),_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh tw-mt-3"},[_c('b-dropdown',{scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('span',{staticClass:"inline-block"},[_vm._v(_vm._s(_vm.$t('payslip.export')))])]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.exportExcel.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-file-excel tw-text-green-500"}),_vm._v(" "+_vm._s(_vm.$t('payslip.excel'))+" ")]),_c('b-dropdown-item',{on:{"click":_vm.exportPdf}},[_c('i',{staticClass:"fas fa-file-pdf tw-text-red-500"}),_vm._v(" "+_vm._s(_vm.$t('payslip.pdf'))+" ")])],1),_c('ts-button',{attrs:{"color":"primary","waiting":_vm.viewing},on:{"click":_vm.fetchData}},[_vm._v(" "+_vm._s(_vm.$t('view'))+" ")])],1)]),_c('div',{staticClass:"col-lg-9"},[_c('div',{staticStyle:{"max-height":"75vh"}},[_c('Table',{staticClass:"tw-rounded-md",attrs:{"columns":_vm.columns,"data":_vm.resources,"size":"small","loading":_vm.loading,"max-height":"700","show-summary":true,"summary-method":_vm.handleSummary,"stripe":""},scopedSlots:_vm._u([{key:"employee_pension_fund",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatNumber( row.employee_pension_fund ))+" ")])]}},{key:"total_pension_fund",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatNumber(row.total_pension_fund))+" ")])]}},{key:"insurance",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatNumber(row.insurance))+" ")])]}},{key:"pension_fund_balance",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatNumber( row.pension_fund_balance ))+" ")])]}},{key:"employer_pension_fund",fn:function(ref){
                            var row = ref.row;
return [_c('strong',[_vm._v(" "+_vm._s(_vm.formatNumber( row.employer_pension_fund ))+" ")])]}}])}),_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end tw-p-4"},[_c('ts-i-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"z-index":1022,"width":"1100px","max-height":"1500","title":_vm.$t('payslip.previewPdf')},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('div',{attrs:{"id":"container"}},[(_vm.previewing)?_c('i',{staticClass:"fa fa-spinner fa-spin fa-2x"}):_vm._e(),_c('preview-pdf',{attrs:{"src":_vm.src,"previewing":_vm.previewing}})],1)])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }